import React, { Fragment } from 'react';
import MainLayout from '../layout/MainLayout';

//PAGES
import Home from './../pages/Home';
import RoomService from '../pages/RoomService';
import Covid from '../pages/Covid';
import EnvPolRoutes from '../pages/EnvSubPages';
import InfoRoutes from '../pages/InfoSubPages/index';

// BAR MENU
import Explore from '../pages/Explore';
import Contact from '../pages/Contact';

import { AnimatedRoutes, RouteTransition } from '../styles/AnimatedRoutes';
import ScrollToTop from '../components/ScrollToTop';

const MainContainer = ({ data, t }) => {
  return (
    <Fragment>
      <MainLayout t={t}>
        <ScrollToTop />
        <AnimatedRoutes>
          <RouteTransition exact path="/" slide={0}>
            <Home data={data} t={t} />
          </RouteTransition>

          <RouteTransition path="/info">
            <InfoRoutes t={t} />
          </RouteTransition>

          <RouteTransition path="/roomservice">
            <RoomService t={t} />
          </RouteTransition>

          <RouteTransition path="/envpolicy" slide={0}>
            <EnvPolRoutes t={t} />
          </RouteTransition>

          <RouteTransition exact path="/covid">
            <Covid t={t} />
          </RouteTransition>

          {/* BOTTOM BAR ROUTES */}
          <RouteTransition exact path="/explore" slide={0}>
            <Explore data={data} t={t} />
          </RouteTransition>
          <RouteTransition exact path="/contact" slide={0}>
            <Contact data={data} t={t} />
          </RouteTransition>
        </AnimatedRoutes>
      </MainLayout>
    </Fragment>
  );
};

export default MainContainer;
