import infoIcon from '../../assets/img/hotel/info.gif';
import RoomServiceIcon from '../../assets/img/hotel/minibar.gif';
import policyIcon from '../../assets/img/hotel/policy.gif';
import covidIcon from '../../assets/img/hotel/covid.png';

const homeData = [
  {
    title: 'Servicios del hotel',
    icon: infoIcon,
    url: '/info',
  },
  {
    title: 'Room service',
    icon: RoomServiceIcon,
    url: '/roomservice',
  },
  {
    title: 'Sostenibilidad',
    icon: policyIcon,
    url: '/envpolicy',
  },
  // {
  //   title: 'Información COVID',
  //   icon: covidIcon,
  //   url: '/covid',
  // },
];

export default homeData;
