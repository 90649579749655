import React from 'react';
import styled from 'styled-components';

const RoomService = ({ t }) => {
  return (
    <RoomServiceWrapper>
      <iframe
        src="/ROOM SERVICE.pdf"
        style={{ height: 'calc(100vh - 128px)', width: '100%' }}
        title="Room service menu"
      />
    </RoomServiceWrapper>
  );
};

const RoomServiceWrapper = styled.div`
  position: relative;
  height: 100%;
`;

export default RoomService;
